import { CookiePopup, Footer, Header, ProgramBanner, ScrollUp } from '@app/components';
import { SiteMap } from '@app/constants';
import { ProtectedRoute } from '@app/features/auth';
import { FC, lazy, Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import './app.scss';

const HomePage = lazy(() => import('@app/pages/home'));
const LoginPage = lazy(() => import('@app/features/auth/login.page'));
const SignUpPage = lazy(() => import('@app/features/auth/signup.page'));
const AccountPage = lazy(() => import('@app/features/account'));
const EventsPage = lazy(() => import('@app/features/events/events.page'));
const EventPage = lazy(() => import('@app/features/events/event.page'));
const ServicesPage = lazy(() => import('@app/features/services/services.page'));
const ServicePage = lazy(() => import('@app/features/services/service.page'));
const BrandsPage = lazy(() => import('@app/features/brands/brands.page'));
const BrandPage = lazy(() => import('@app/features/brands/brand.page'));
const FoodPage = lazy(() => import('@app/features/food/food.page'));
const RestaurantPage = lazy(() => import('@app/features/food/restaurant.page'));
const StoriesPage = lazy(() => import('@app/features/stories/stories.page'));
const StoryPage = lazy(() => import('@app/features/stories/story.page'));
const MapsPage = lazy(() => import('@app/features/maps'));
const LoyalityOverviewPage = lazy(() => import('@app/features/loyality'));
const AboutPage = lazy(() => import('@app/pages/about'));
const ContactsPage = lazy(() => import('@app/pages/contacts'));
const InfoPage = lazy(() => import('@app/pages/info'));
const SearchPage = lazy(() => import('@app/features/search'));
const NotFoundPage = lazy(() => import('@app/pages/not-found'));
const UnsortedPage = lazy(() => import('@app/pages/unsorted'));

const CertificatesPage = (): null => {
  window.location.href = 'https://gifts.tsvetnoy.com';
  return null;
};

const App: FC = () => {
  const disableScrollUpOn = useMemo(() => [{ from: SiteMap.MAP, to: SiteMap.MAP }], []);

  return (
    <div className="app">
      <Header />

      <Suspense fallback={<div className="app__page-fallback" />}>
        <Routes>
          <Route path={SiteMap.HOME} element={<HomePage />} />
          <Route path={SiteMap.LOGIN} element={<LoginPage />} />
          <Route path={SiteMap.SIGN_UP} element={<SignUpPage />} />
          <Route path={SiteMap.ACCOUNT} element={<Navigate to={SiteMap.LOYALITY} replace />} />
          <Route
            path={`${SiteMap.ACCOUNT}/*`}
            element={
              <ProtectedRoute>
                <AccountPage />
              </ProtectedRoute>
            }
          />
          <Route path={SiteMap.EVENTS} element={<EventsPage />} />
          <Route path={`${SiteMap.EVENTS}/:id`} element={<EventPage />} />
          <Route path={SiteMap.SERVICES} element={<ServicesPage />} />
          <Route path={`${SiteMap.SERVICES}/:id`} element={<ServicePage />} />
          <Route path={SiteMap.BRANDS} element={<BrandsPage />} />
          <Route path={`${SiteMap.BRANDS}/:id`} element={<BrandPage />} />
          <Route path={SiteMap.FOOD} element={<FoodPage />} />
          <Route path={`${SiteMap.FOOD}/:id`} element={<RestaurantPage />} />
          <Route path={SiteMap.CONTACTS} element={<ContactsPage />} />
          <Route path={SiteMap.STORIES} element={<StoriesPage />} />
          <Route path={`${SiteMap.STORIES}/:id`} element={<StoryPage />} />
          <Route path={SiteMap.INFO} element={<Navigate to={`${SiteMap.PRIVACY_POLICY}`} replace />} />
          <Route path={`${SiteMap.INFO}/*`} element={<InfoPage />} />
          <Route path={SiteMap.MAP} element={<Navigate to={`${SiteMap.MAP}/1`} replace />} />
          <Route path={`${SiteMap.MAP}/:floor/:cornerId?`} element={<MapsPage />} />
          <Route path={SiteMap.LOYALITY_OVERVIEW} element={<LoyalityOverviewPage />} />
          <Route path={SiteMap.ABOUT} element={<AboutPage />} />
          <Route path={SiteMap.CERTIFICATES} element={<CertificatesPage />} />
          <Route path={SiteMap.SEARCH} element={<SearchPage />} />
          <Route path={SiteMap.UNSORTED} element={<UnsortedPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>

      <ProgramBanner />
      <Footer />
      <ScrollUp disableScrollUpOn={disableScrollUpOn} />
      <CookiePopup />
    </div>
  );
};

export default App;
