import { useLazyAuthControllerProfileQuery } from '@app/api/auth-api';
import { useEffect, useState } from 'react';
import { isTokenExisting } from './auth.utils';

export const useLoginCheck = (
  onLoggedIn: (() => void) | null,
  onLoggedOut: (() => void) | null,
): boolean | undefined => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [noToken, setNoToken] = useState<boolean>();

  const [checkProfile, { isFetching, isSuccess, isError }] = useLazyAuthControllerProfileQuery();

  useEffect(function runCheck() {
    if (isTokenExisting()) {
      checkProfile();
      setNoToken(false);
    } else {
      setNoToken(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function handleCheckStatus() {
      if (isFetching) {
        return;
      }
      if (isSuccess) {
        setIsLoggedIn(true);
        onLoggedIn?.();
      } else if (isError || noToken) {
        setIsLoggedIn(false);
        onLoggedOut?.();
      }
    },
    [isSuccess, isFetching, isError, noToken, onLoggedIn, onLoggedOut],
  );

  return isLoggedIn;
};
