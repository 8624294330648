import { ReactComponent as CrossIcon } from '@app/assets/icons/cross.svg';
import { ReactComponent as MenuIcon } from '@app/assets/icons/menu.svg';
import { Drawer } from '@app/components';
import { useElementSize } from '@app/hooks';
import cx from 'classnames';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './burger-menu.scss';

type BurgerMenuProps = {
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  children?: ReactNode;
  headerContent?: ReactNode;
};

export const BurgerMenu: FC<BurgerMenuProps> = ({
  className,
  children,
  headerClassName,
  contentClassName,
  headerContent,
}) => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const { height: menuWrapperHeight } = useElementSize(menuWrapperRef);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  return (
    <>
      <menu className={cx(className, 'burger-menu__menu')}>
        <button type="button" aria-label="menu" className="burger-menu__btn" onClick={() => setActive(true)}>
          <MenuIcon />
        </button>
      </menu>
      <Drawer opened={active} onClickOutside={() => setActive(false)} className="burger-menu__container">
        <div ref={menuWrapperRef} className={cx(headerClassName, 'burger-menu__header')}>
          {headerContent}
          <button type="button" aria-label="menu" className="burger-menu__btn" onClick={() => setActive(false)}>
            <CrossIcon />
          </button>
        </div>
        <div
          className={cx(contentClassName, 'burger-menu__content')}
          style={{ height: `calc(100% - ${menuWrapperHeight}px)` }}
        >
          {children}
        </div>
      </Drawer>
    </>
  );
};
