/* eslint-disable @typescript-eslint/no-unused-vars */
import { LocalStorageItem } from '@app/constants';
import { removeToken, saveToken } from '@app/features/auth';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

const REFRESH_URL = '/api/v1/auth/refresh';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL || '/',
  credentials: process.env.NODE_ENV === 'development' ? 'omit' : 'include',
  mode: 'cors', // TODO!
  prepareHeaders: (headers) => {
    const token = localStorage.getItem(LocalStorageItem.TOKEN);
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (!result.error || result.error.status !== 401) {
    return result;
  }

  if (!mutex.isLocked()) {
    const release = await mutex.acquire();

    try {
      const refreshResult = await baseQuery(REFRESH_URL, api, extraOptions);

      if (refreshResult.data) {
        const { accessToken } = refreshResult.data as { accessToken: string };
        saveToken(accessToken);

        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        removeToken();
      }
    } finally {
      release();
    }
  } else {
    await mutex.waitForUnlock();
    result = await baseQuery(args, api, extraOptions);
  }

  return result;
};

export const connectEmptyApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
