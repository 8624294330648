import { connectEmptyApi } from '@app/api/connect-empty-api';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    [connectEmptyApi.reducerPath]: connectEmptyApi.reducer,
  },
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().concat([connectEmptyApi.middleware]),
  devTools: true, // TODO! вынести в конфик, отлкючать на проде
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
