import { connectEmptyApi as api } from './connect-empty-api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerLogin: build.mutation<AuthControllerLoginApiResponse, AuthControllerLoginApiArg>({
      query: (queryArg) => ({ url: '/api/v1/auth/login', method: 'POST', body: queryArg.profileLoginDto }),
    }),
    authControllerLogout: build.query<AuthControllerLogoutApiResponse, AuthControllerLogoutApiArg>({
      query: () => ({ url: '/api/v1/auth/logout' }),
    }),
    authControllerRefresh: build.query<AuthControllerRefreshApiResponse, AuthControllerRefreshApiArg>({
      query: () => ({ url: '/api/v1/auth/refresh' }),
    }),
    authControllerProfile: build.query<AuthControllerProfileApiResponse, AuthControllerProfileApiArg>({
      query: () => ({ url: '/api/v1/auth/profile' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type AuthControllerLoginApiResponse = /** status 200 Return single entry. */ ProfileLoginResponseDto;
export type AuthControllerLoginApiArg = {
  profileLoginDto: ProfileLoginDto;
};
export type AuthControllerLogoutApiResponse = unknown;
export type AuthControllerLogoutApiArg = void;
export type AuthControllerRefreshApiResponse = unknown;
export type AuthControllerRefreshApiArg = void;
export type AuthControllerProfileApiResponse = /** status 200 Return single entry. */ AuthProfileResponseDto;
export type AuthControllerProfileApiArg = void;
export type ProfileLoginResponseDto = {
  accessToken: string;
  refreshToken: string;
};
export type ProfileLoginDto = {
  phone?: string;
  code: string;
  captcha?: string | null;
};
export type AuthProfileResponseDto = {
  name: string;
};
export const {
  useAuthControllerLoginMutation,
  useAuthControllerLogoutQuery,
  useLazyAuthControllerLogoutQuery,
  useAuthControllerRefreshQuery,
  useLazyAuthControllerRefreshQuery,
  useAuthControllerProfileQuery,
  useLazyAuthControllerProfileQuery,
} = injectedRtkApi;
