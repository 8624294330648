import { SiteMap } from '@app/constants';
import { FC, ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isRegistered, removeToken } from './auth.utils';
import { useLoginCheck } from './login-check.hook';

export const ProtectedRoute: FC<{ children: ReactElement }> = ({ children }) => {
  const navigate = useNavigate();
  const [signUpNeeded, setSignUpNeeded] = useState(false);

  const onLoggedIn = useCallback(() => {
    if (!isRegistered()) {
      setSignUpNeeded(true);
      navigate(SiteMap.SIGN_UP, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoggedOut = useCallback(() => {
    removeToken();
    navigate(SiteMap.LOGIN, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggedIn = useLoginCheck(onLoggedIn, onLoggedOut);

  return isLoggedIn && !signUpNeeded && children ? children : <div style={{ height: '100vh' }} />;
};
