import { ArrowLink } from '@app/components';
import { SiteMap } from '@app/constants';
import { useEnhancedTranslation } from '@app/hooks';
import { ymGoal } from '@app/utils';
import { FC } from 'react';

import './program-banner.scss';

/** TODO rename to LoyalityBanner */
export const ProgramBanner: FC = () => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'programBanner' });

  return (
    <section className="program-banner">
      <div className="program-banner__banner">
        <div className="program-banner__title-group">
          <h2 className="program-banner__title">{t('title')}</h2>
          <p className="program-banner__description">{t('description')}</p>
        </div>
        <ArrowLink
          className="program-banner__join-link"
          to={SiteMap.LOYALITY_OVERVIEW}
          onClick={() => ymGoal('connectfoot')}
        >
          {t('join')}
        </ArrowLink>
      </div>
    </section>
  );
};
